import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "react-loader-spinner";

import axios from "axios";
import { get } from "../helpers/get";

import { Layout } from "../components/molecules/Layout";
import { Metrics } from "../components/atoms/Metrics";

import { siteurldetail } from "../api";

export const SiteurlDetail = () => {
  let location = useLocation();
  let id = location.pathname.split("/")[2];

  const [sitedetail, setSitedetail] = useState([]);
  useEffect(() => {
    axios
      .get(siteurldetail(id))
      .then(response => {
        setSitedetail(response.data);
      })
      .catch(error => console.log(error));
  }, [id]);
  return (
    <Layout headerName={sitedetail.url} className={"createUrl"}>
      {sitedetail.url ? (
        <section className="projectmetrics">
          <h2>Metrics</h2>
          <section className="projectmetrics__boxcontainer">
            {sitedetail?.lastMobileResult?.scores.map(e => {
              if (
                e.scoreType.type != "TCP" &&
                e.scoreType.type != "TCA" &&
                e.scoreType.type != "TCS" &&
                e.scoreType.type != "TCBP" &&
                e.scoreType.type != "TCPWA"
              ) {
                return (
                  <Metrics
                    colorCard={get.color(e.value * 100)}
                    name={e.scoreType.description}
                    url={e?.url}
                    content={Math.round(e.value * 100) + "%"}
                  />
                );
              }
            })}
          </section>
        </section>
      ) : (
        <div class="center-screen">
          <Grid height="100" width="100" color="#0A006F" ariaLabel="loading" />
        </div>
      )}
    </Layout>
  );
};
