import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { config } from "../helpers/config";
import { useNavigate } from "react-router-dom";

import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";
import { Input } from "../components/atoms/Input";
import { Button } from "../components/atoms/Button";
import {
  deleteProject,
  projectdetail,
  projectsurl,
  projectsurlput,
} from "../api";
import axios from "axios";
import { projectype } from "../api";

export const EditProject = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [projectid, setProjectid] = useState(location.pathname.split("/")[2]);
  const [project, setProject] = useState({});
  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState([]);
  const [projectType, setProjecttype] = useState([]);
  useEffect(() => {
    axios
      .get(projectype)
      .then(response => {
        setProjecttype(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get(projectdetail(projectid))
      .then(res => {
        res.data.active? res.data.active=1:res.data.active=0
        setProject(res.data);
        setPreview(res.data.logo);
      })
      .catch(error => console.log(error));
  }, []);
  const onImageChange = e => {
    setImages(e.target.files[0]);
  };
  useEffect(() => {
    if (images.length < 1) return;
    const object = URL.createObjectURL(images);
    setPreview(object);
  }, [images]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = data => {
    console.log(data);
    let numWeight = Number(data.Weight);
    axios
      .put(
        projectsurlput(projectid),
        JSON.stringify({
          name: data.Name,
          weight: numWeight,
          mainUrl: data.MainURL,
          updateFileName: "test",
          logo: preview,
          active: Number(data.State),
          projectType: data.ProjectType,
        }),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(res => {
        console.log(res);
        alert("Succesvol aangepast!");
        navigate(-1);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const onDelete = () => {
    axios
      .delete(deleteProject(projectid))
      .then(response => {
        alert("Project verwijderd!");
        navigate(-1);
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  };
  const goBack = () => {
    navigate(-1);
  };
  setValue("Name", project.name);
  setValue("ProjectType", project.projectType);
  setValue("User", "Admin");
  setValue("Weight", project.weight);
  project.active === false
    ? setValue("State", Number(0))
    : setValue("State", Number(1));

  setValue("State", project.active);
  setValue("MainURL", project.mainUrl);
  return (
    <>
    {console.log(project)}
      <Layout headerName="Edit Project" className="editProject">
        {project.logo && (
          <section className="editProject__header">
            {project.logo.slice(0, 4) === "blob" ? (
              <div>
                <img
                  className="editProject__header__logo"
                  src={project.logo}
                ></img>
              </div>
            ) : (
              <div
                className="editProject__header__logo"
                dangerouslySetInnerHTML={{ __html: project.logo }}
              ></div>
            )}
          </section>
        )}
        <form
          className="form"
          onSubmit={handleSubmit(onSubmit)}
          // buttons={["cancel", "Create"]}
          // oncreate={() => console.log("clicked")}
        >
          <section className="form__fields">
            <Input
              label="Name"
              placeholder="Project name"
              register={register}
              value={project.name}
            />
            {projectType && projectType[0] && (
              <Input
                def={projectType[0]["@id"]}
                label="ProjectType"
                select
                register={register}
              >
                {projectType.map(e => (
                  <option key={e.type} value={e["@id"]}>
                    {e.type}
                  </option>
                ))}
              </Input>
            )}

            <Input label="User" select register={register} def="Admin">
              {config.users().map(e => (
                <option key={e}>{e}</option>
              ))}
            </Input>
            <Input
              label="Weight"
              type={"number"}
              placeholder="69MB"
              register={register}
              value={project.weight}
            />
            <section className="projectform__logoAndPreview fullWidth">
              {project?.active!==undefined && (
              <Input label="State" select register={register} def={project?.active?1:0}>
                <option value={Number(1)}>Active</option>
                <option value={Number(0)}>Inactive</option>
              </Input>
              )}
              <section>
                <section className="halfWidth">
                  <label>Logo</label>

                  <label className="btn btn--primary input-logo">
                    upload file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                  </label>
                </section>
                <section className="halfWidth">
                  <label>Preview</label>
                  {/* LOGO */}
                  {images && <img src={preview} />}
                </section>
              </section>
            </section>
            <Input
              label="MainURL"
              large
              placeholder="https://main-url"
              register={register}
              value={project.mainUrl}
            />
          </section>
          <section className="form__buttons">
            <span className="delete">
              <Button
                type="button"
                className="btn btn--secondary"
                name="Delete"
                onClick={() => onDelete()}
              />
            </span>
            <span>
              <Button
                type="button"
                className="btn btn--cancel"
                name="Cancel"
                onClick={() => goBack()}
              />
            </span>
            <span>
              <Button
                type="submit"
                className="btn btn--primary"
                name="Update"
              />
            </span>
          </section>
        </form>
      </Layout>
    </>
  );
};
