import { CgScreen, CgSmartphone } from "react-icons/cg";

import { Button } from "./Button";
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
export const CardItem = ({
  dev,
  logo,
  mobile,
  desktop,
  path,
  id,
  name,
  status,
}) => {
  const currentid = id?.split("/")[3];
  const currentUrl = window.location.href.split("/");
  const currentPath = currentUrl[currentUrl.length - 1];
  // Color Mobile/Desktop on the cards (text and icons)
  let colorMobile = "card__mobile ";
  let textMobile = "card__text ";
  let colorDesktop = "card__desktop ";
  let textDesktop = "card__text ";
  const color = score => {
    return score < 50 ? "red" : score >= 90 ? "green" : "orange";
  };

  const cards = (m, d) => {
    colorMobile += `card__mobile--${color(m)}`;
    textMobile += `card__mobile--${color(m)}`;

    colorDesktop += `card__mobile--${color(d)}`;
    textDesktop += `card__mobile--${color(d)}`;
  };
  cards(mobile, desktop);

  // Color statusline upper card
  let statusColor = "statusLine ";
  if ((mobile + desktop) / 2 < 50) statusColor += "statusLine--red";
  else if ((mobile + desktop) / 2 >= 90) statusColor += "statusLine--green";
  else statusColor += "statusLine--orange";

  return (
    <>
      <div className="card carditem">
        {status ? (
          <div
            className={statusColor}
            style={
              status === "fail" || undefined
                ? { backgroundColor: "#e776a2" }
                : { backgroundColor: "#7ee1b8" }
            }
          ></div>
        ) : (
          <div
            className={statusColor}
            style={{
              backgroundColor: `${(status = "fail")}`,
            }}
          ></div>
        )}

        <div className="card__logocontainer carditem__logocontainer">
          {logo ? (
            logo.slice(0, 4) === "blob" ? (
              <div>
                <img className="card__logo carditem__logo" src={logo}></img>
              </div>
            ) : (
              <div
                className="card__logo carditem__logo"
                dangerouslySetInnerHTML={{ __html: logo }}
              ></div>
            )
          ) : (
            <h3>{name}</h3>
          )}
        </div>
        {mobile && (
          <div className="card__scores">
            <div className="card__box">
              <CgSmartphone className={colorMobile} />
              <p className={textMobile}>{mobile}%</p>
            </div>
            <div className="card__box">
              <CgScreen className={colorDesktop} />
              <p className={textDesktop}>{desktop}%</p>
            </div>
          </div>
        )}
        {!mobile &&
          (status === "pass" ? (
            <FiCheckCircle className="jobstable__box__check"></FiCheckCircle>
          ) : (
            <FiAlertTriangle className="jobstable__box__error"></FiAlertTriangle>
          ))}
        {path === currentPath && (
          <>
            <Button
              className={"btn btn--primary carditem__btn"}
              icon={"edit"}
              path={`projects/${currentid}/editproject`}
            />
            {dev === "Drupal" ? (
              <img className="carditem__dev" src="./drupal.png" alt="drupal" />
            ) : (
              <img
                className="carditem__dev"
                src="./wordpress.png"
                alt="wordpress"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
