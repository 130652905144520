import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { syntheticJobsProject } from "../api";
import axios from "axios";
import { Grid } from "react-loader-spinner";
import { Layout } from "../components/molecules/Layout";
import { Jobtable } from "../components/atoms/Jobtable";
import { Button } from "../components/atoms/Button";

export const JobList = () => {
  let location = useLocation();
  const [projectid, setProjectid] = useState(location.pathname.split("/")[2]);
  const [syntheticjobs, setSyntheticjobs] = useState([]);

  useEffect(() => {
    axios
      .get(syntheticJobsProject(projectid))
      .then(response => {
        setSyntheticjobs(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, [projectid]);

  return (
    <>
      {syntheticjobs ? (
        <Layout headerName={"Synthetic Job List"}>
          <Button
            className="btn btn--primary projects__add"
            icon="plus"
            path={`syntheticform/${projectid}`}
          />
          {syntheticjobs.map(job => (
            <Link to={"/jobs/" + job["@id"].split("/")[3] + "/detail"}>
              <Jobtable job={job} />
            </Link>
          ))}
        </Layout>
      ) : (
        <div class="center-screen">
          <Grid height="100" width="100" color="#0A006F" ariaLabel="loading" />
        </div>
      )}
    </>
  );
};
