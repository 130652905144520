import { LoginForm } from "../components/molecules/Form";
import {
  PageSpeedLogo,
  PageSpeedLogoMobile,
  LoginDrawing,
  LoginBackgroundMobile,
} from "../components/atoms/Svg";

export const Login = () => {
  return (
    <div className="container">
      <section className="form">
        <div className="form__titleBox">
          <h1 className="form__title">Log in</h1>
        </div>
        <LoginForm className="form__container" />
      </section>
      <section className="box">
        <PageSpeedLogo className="box__name"/>
      </section>
      <LoginDrawing className="container__image" />
      <LoginBackgroundMobile className="container__mobile_login" />
      <PageSpeedLogoMobile className="container__mobile_name" />
    </div>
  );
};
