import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";

import { Input } from "../components/atoms/Input";

import { EditScoreTypeBackground } from "../components/atoms/Svg";

export const EditScoreType = () => {
  return (
    <>
      <Layout className="editScoreType" headerName="Edit ScoreType">
        <Form buttons={["Delete", "Cancel", "Update"]}>
          <Input label="Type" />
          <Input label="Unit" />
          <Input label="Description" />
        </Form>
      </Layout>
      <EditScoreTypeBackground className="editScoretypeBackground" />
    </>
  );
};
