import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { resultsLastMonth } from "../../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const Barchart = ({type}) => {
  let location = useLocation();
  let id = location.pathname.split("/")[2];
  const [resultslastmonth, setResultslastmonth] = useState([]);
  var url = resultsLastMonth({ projectid: id, type: type });

  useEffect(() => {
    axios
      .get(url)
      .then(response => {
        setResultslastmonth(response.data);
      })
      .catch(error => console.log(error));
  }, [type]);

  const data = {
    labels: resultslastmonth.label,
    datasets: [
      {
        label: "Performance",
        data: resultslastmonth,
        borderColor: "#0A006F",
        backgroundColor: "#0A006F",
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
        barThickness: 20,
      },
    ],
  };
  return <Bar className="bar" options={options} data={data} />;
};
