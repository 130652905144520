import React, { useEffect, useState } from "react";
import {get} from "../../helpers/get";

export const Gauge = ({ value }) => {
  const [checkvalue, setCheckvalue] = useState();
  useEffect(() => {
    value && setCheckvalue(value);
  }, []);

  function setGaugeValue(v) {
    if (v < 0 || v > 1) {
      return;
    }
  }

  const styling = {
    background: `${get.color(checkvalue)}`,
    transform: `rotate(${checkvalue / 100 / 2}turn)`,
  };

  return checkvalue && setGaugeValue(checkvalue) ? null : (
    <div className="gauge">
      <div className="gauge__body">
        <div className="gauge__fill" style={styling}></div>
        <div className="gauge__cover">{Math.round(checkvalue)}%</div>
      </div>
    </div>
  );
};
