import React, { useEffect, useState } from "react";
import axios from "axios";
import { syntheticJobDetail } from "../../api";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";

import {
  FiAlertTriangle,
  FiCheckCircle,
} from "react-icons/fi";

export const Jobtable = ({ job }) => {
  const [syntheticdetail, setSyntheticdetail] = useState();
  const [id] = useState(job["@id"].split("/")[3]);
  const [token] = useState(Cookies.get("bearer_token"));
  useEffect(() => {
    axios
      .get(syntheticJobDetail(id), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        setSyntheticdetail(response.data);
      })
      .catch(error => console.log(error));
  }, [id]);
  return (
    <div className="jobstable__box boxshadow">
      <div className="jobstable__box__link">
        <p>{job?.name}</p>
      </div>
      <div className="jobstable__box__status">
        {syntheticdetail?.output?.status?(
          syntheticdetail?.output?.status === "pass"
        ) |
        (syntheticdetail?.output?.status === "fail") ? (
          syntheticdetail?.output?.status === "pass" ? (
            <FiCheckCircle className="jobstable__box__check"></FiCheckCircle>
          ) : (
            <FiAlertTriangle className="jobstable__box__error"></FiAlertTriangle>
          )
        ) : (
          <p>Not tested</p>
        ):<Oval height="28" width="28" color="#0A006F" ariaLabel="loading"></Oval>}
      </div>
    </div>
  );
};
