export const deploy = "https://api.mediasoft.be";
export const dev = "http://monitor-mediasoft.ddev.site";

export const authurl = deploy + "/authentication_token";

export const user = email => {
  return deploy + "/api/users?page=1&pagination=false&email=" + email;
};

export const projectsurl = deploy + "/api/projects?page=1&pagination=false";

export const projectsurlpost = deploy + "/api/projects";
export const projectsurlput = id => {
  return deploy + "/api/projects/" + id;
};

export const projectdetail = currentPath => {
  return deploy + "/api/projects/" + currentPath;
};
export const worsturlsurl = deploy + "/api/site_urls/worsturls";

export const siteurlsurl = deploy + "/api/site_urls";

export const siteurldetail = currentPath => {
  return deploy + "/api/site_urls/" + currentPath;
};
export const projectype = deploy + "/api/project_types?page=1&pagination=false";

export const totalPages = ({ strategy, type, lower, higher, projectid }) => {
  return (
    deploy +
    "/result/showTotalBadPagesGraphic?type=" +
    type +
    "&strategy=" +
    strategy +
    "&lower=" +
    lower +
    "&higher=" +
    higher +
    "&project_id=" +
    projectid
  );
};
export const totalPagesNoId = ({
  strategy,
  type,
  lower,
  higher,
  projectid,
}) => {
  return (
    deploy +
    "/result/showTotalBadPagesGraphic?type=" +
    type +
    "&strategy=" +
    strategy +
    "&lower=" +
    lower +
    "&higher=" +
    higher
  );
};

export const resultsLastMonth = ({ projectid, type }) => {
  return deploy + "/result/results-last-month/" + projectid + "?type=" + type;
};

export const projectSiteUrls = ({ projectid, page }) => {
  return (
    deploy +
    "/api/site_urls?page=" +
    page +
    "&pagination=true&project=" +
    projectid
  );
};

export const errorstitle = id => {
  return (
    deploy +
    "/result/showHTMLStructureErrorCounter?site_url_id=" +
    id +
    "&tag=h1&print=counter"
  );
};

export const worsturlsproject = id => {
  return deploy + id + "/worstUrls";
};

export const allSyntheticjobs = deploy + "/api/synthetic_jobs?page=1&pagination=false";

export const syntheticJobsProject = id => {
  return deploy + "/api/synthetic_jobs?page=1&pagination=false&project=" + id;
};

export const syntheticJobDetail = id => {
  return deploy + "/api/synthetic_jobs/" + id + "/detail";
};

export const deleteProject = id => {
  return deploy + "/api/projects/" + id + "/deleteproject";
};

export const addSynthetic = deploy + "/synthetic_job/new";

export const editSynthetic = id =>{return deploy + "/synthetic_job/" + id + "/edit";};

export const deleteSyntheticJob = (id) => {
  return deploy + "/synthetic_job/" + id;
};
export const runSyntheticJob = (id) => {
  return deploy + "/api/synthetic_jobs/" + id + "/run";
};
