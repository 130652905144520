import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiAlignRight } from "react-icons/fi";
import { Mainlogo } from "../atoms/Svg";

// TODO
// If menu is opend show a x-mark instead of a hamburger menu

export const Header = ({ name, type, img }) => {
  const navigate = useNavigate();

  const toMenu = () => {
    navigate("/menu");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <header>
      <nav>
        {type === "minimal" ? (
          <ul className="navigationminimal">
            <li className="navigationminimal__logo">
              <a href="/dashboard">
                <Mainlogo className="navigationminimal__logo__svg" />
              </a>
            </li>
            <li className="navigationminimal__title">
              <h1>{name}</h1>
            </li>
            <li onClick={toMenu} className="navigationminimal__hamburger">
              <FiAlignRight />
            </li>
          </ul>
        ) : (
          <ul className="navigation">
            <li className="navigation__logo">
              <a href="/dashboard">
                <Mainlogo className="navigationminimal__logo__svg" />
              </a>
            </li>
            <li onClick={toMenu} className="navigation__hamburger">
              <FiAlignRight className="navigation__hamburger__img" />
            </li>
            <li className="navigation__back">
              <FiChevronLeft
                onClick={goBack}
                className="navigation__back__img"
              />
            </li>
            <li className="navigation__title">
              {img ? (
                img.slice(0, 4) === "blob" ? (
                  <div>
                    <img className="navigation__title__img" src={img}></img>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: img }}></div>
                )
              ) : (
                <h1>{name}</h1>
              )}
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
};
