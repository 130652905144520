import React, { useEffect, useState } from "react";
import { Grid } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";

import { Layout } from "../components/molecules/Layout";

import { CardItem } from "../components/atoms/CardItem";
import { Button } from "../components/atoms/Button";
import {
  ProjectsBackground1,
  ProjectsBackground2,
} from "../components/atoms/Svg";
import { projectsurl } from "../api";

export const Projects = ({ path }) => {
  const [projects, setProjects] = useState([]);
  const [userid] = useState(Cookies.get("userid").split("/")[3]);

  useEffect(() => {
    Cookies.get("role").split(",").includes("ROLE_ADMIN")
      ? axios
          .get(projectsurl)
          .then(response => {
            setProjects(response.data["hydra:member"]);
          })
          .catch(error => console.log(error))
      : axios
          .get(projectsurl + "&active=1&user=" + userid)
          .then(response => {
            setProjects(response.data["hydra:member"]);
          })
          .catch(error => console.log(error));
  }, []);

  return (
    <>
      <Layout headerName="Projects" className="projects">
        {projects != 0 ? (
          <>
            <Button
              className="btn btn--primary projects__add"
              icon="plus"
              path="projectform"
            />
            <div className="projects__container">
              {projects.map(e => {
                return (
                  <Link to={"/projects/" + e["@id"].split("/")[3]}>
                    <CardItem
                      dev={e.development}
                      key={e.name}
                      name={e.name}
                      path={path}
                      logo={e.logo}
                      mobile={Math.round(
                        e?.mainurl_id?.lastMobileResult?.scores[6]?.valueProcent
                      )}
                      desktop={Math.round(
                        e?.mainurl_id?.lastDesktopResult?.scores[6]
                          ?.valueProcent
                      )}
                      id={e["@id"]}
                    />
                  </Link>
                );
              })}
            </div>
          </>
        ) : (
          <div class="center-screen">
            <Grid
              height="100"
              width="100"
              color="#0A006F"
              ariaLabel="loading"
            />
          </div>
        )}
      </Layout>
      <ProjectsBackground1 className="projects__bg1" />
      <ProjectsBackground2 className="projects__bg2" />
    </>
  );
};
