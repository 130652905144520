import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";

import { Input } from "../components/atoms/Input";
import { EditUserBackground } from "../components/atoms/Svg";

export const EditUser = () => {
  return (
    <>
      <Layout className="editUser" headerName="Edit User">
        <Form
          title="Edit profile"
          buttons={["Delete", "Cancel", "Create"]}
          onDelete={() => console.log("test")}
        >
          <Input label="Mail" />
          <Input label="Role" select>
            <option>Admin</option>
          </Input>
        </Form>
        <Form title="Change profle" buttons={["Delete", "Cancel", "Create"]}>
          <section className="fullWidth">
            <Input label="Current password" type="password" />
          </section>
          <Input label="New password" type="password" />
          <Input label="Confirm new password" type="password" />
        </Form>
      </Layout>
      <EditUserBackground className="editUserBackground" />
    </>
  );
};
