import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";

import { CardItem } from "../components/atoms/CardItem";
import {
  allSyntheticjobs,
  projectdetail,
  projectsurl,
  syntheticJobsProject,
} from "../api";
import { Layout } from "../components/molecules/Layout";

export const JobProjects = () => {
  const [allprojects, setAllprojects] = useState([]);
  const [projectids, setProjectids] = useState([]);
  const [userid] = useState(Cookies.get("userid").split("/")[3]);
  const [projectid, setProjectid] = useState();
  useEffect(() => {
    axios
      .get(projectsurl + "&active=1&user=" + userid)
      .then(result => setProjectid(result.data["hydra:member"][0]["@id"].split("/")[3]))
      .catch(error => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get(allSyntheticjobs)
      .then(response => {
        setAllprojects(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    const tempprojectids = [];
    allprojects?.length > 0 &&
      allprojects?.map(p => {
        !tempprojectids.includes(p?.project?.split("/")[3]) &&
          tempprojectids.push(p?.project?.split("/")[3]);
      });
    tempprojectids.length > 0 && setProjectids(tempprojectids);
  }, [allprojects]);

  return (
    <>
      <Layout headerName={"Synthetic Job Projects"} className="dashboard2">
        {Cookies.get("role").split(",").includes("ROLE_ADMIN") ? (
          projectids?.length > 0 &&
          projectids?.map(id => <JobProject id={id} />)
        ) : (
          <JobProject id={projectid} />
        )}
      </Layout>
    </>
  );
};

const JobProject = id => {
  const [project, setProject] = useState();
  const [jobs, setJobs] = useState();
  const [statusjob, setStatusjob] = useState();
  console.log(id);
  useEffect(() => {
    Cookies.get("role").split(",").includes("ROLE_ADMIN")
      ? axios
          .get(projectdetail(id?.id))
          .then(response => {
            setProject(response.data);
          })
          .catch(error => console.log(error))
      : axios
          .get(projectdetail(id?.id))
          .then(response => {
            setProject(response.data);
          })
          .catch(error => console.log(error));
  }, [id]);
  useEffect(() => {
    Cookies.get("role").split(",").includes("ROLE_ADMIN")
      ? axios
          .get(syntheticJobsProject(id?.id))
          .then(response => {
            setJobs(response.data["hydra:member"]);
          })
          .catch(error => console.log(error))
      : axios
          .get(syntheticJobsProject(id?.id))
          .then(response => {
            setJobs(response.data["hydra:member"]);
          })
          .catch(error => console.log(error));
  }, [id]);
  useEffect(() => {
    const tempstatus = [];
    jobs?.map(j => {
      tempstatus.push(j?.lastSyntheticResult?.status);
    });
    tempstatus?.includes("fail") || tempstatus?.includes(undefined)
      ? setStatusjob("fail")
      : setStatusjob("pass");
  }, [jobs]);
  return project ? (
    <Link key={project["@id"]} to={"/jobs/" + project["@id"].split("/")[3]}>
      <CardItem status={statusjob} name={project.name} logo={project.logo} />
    </Link>
  ) : null;
};
