import { FiEdit, FiTrash2, FiPlusCircle, FiEye,FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";

export const Button = ({ name, className, type, onClick, icon, path }) => {
  const correctSvg = icon => {
    if (icon === "edit") return <FiEdit className={"btn--img"} />;
    else if (icon === "trash") return <FiTrash2 className={"btn--img"} />;
    else if (icon === "plus") return <FiPlusCircle className={"btn--img"} />;
    else if (icon === "eye") return <FiEye className={"btn--img"} />;
    else if (icon === "play") return <FiPlay className={"btn--img"} />;

  };

  return (
    <>
      {path && path !== "" ? (
        <Link
          to={`/${path}`}
          className={className}
          onClick={onClick}
          type={type}
        >
          {name ? name : correctSvg(icon)}
        </Link>
      ) : (
        <button className={className} onClick={onClick} type={type}>
          {name ? name : correctSvg(icon)}
        </button>
      )}
    </>
  );
};
