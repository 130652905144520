import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "../atoms/Button";
import { Input } from "../atoms/Input";
import { authurl, user } from "../../api";

export const LoginForm = ({ className }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailChangeHandler = e => {
    setEmail(e.target.value);
  };

  const passwordChangeHandler = e => {
    setPassword(e.target.value);
  };

  const fetchData = async e => {
    e.preventDefault();

    return await axios
      .post(
        authurl,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(res => {
        Cookies.set("bearer_token", res.data.token);
        Cookies.set("refresh_token", res.data.refresh_token);
        Cookies.set("email", email);
        axios
          .get(user(email))
          .then(response => {
            console.log(response);
            Cookies.set("role", response.data["hydra:member"][0].roles);
            Cookies.set("userid", response.data["hydra:member"][0]["@id"]);
            navigate("/dashboard");
          })
          .catch(error => console.log(error));
      })
      .catch(error => {
        console.log(error);
        navigate("/");
      });
  };

  return (
    <form className={className}>
      <div className="form__box">
        <label className="form__label">email</label>
        <Input
          id="email"
          className="form__input"
          onChange={emailChangeHandler}
          large
        />
      </div>
      <div className="form__box">
        <label className="form__label">Password</label>
        <Input
          id="password"
          type="password"
          className="form__input"
          onChange={passwordChangeHandler}
          large
        />
      </div>
      <Button
        onClick={fetchData}
        type={"submit"}
        name="Log in"
        className="btn btn--primary form__btn"
      />
    </form>
  );
};

export const ProjectForm = ({ className }) => {
  return (
    <>
      <form className={className}>
        <div className="projectform__container__box">
          <div className="projectform__container__content">
            <label className="projectform__container__label">
              Project name
            </label>
            <Input
              id="name"
              className="projectform__container__input input input--small"
            />
          </div>
          <div className="projectform__container__content">
            <label className="projectform__container__label">
              Project type
            </label>
            <Input
              id="type"
              className="projectform__container__input input input--small"
            />
          </div>
        </div>
        <Button
          type={"submit"}
          name="Add"
          className="btn btn--primary projectform__container__btn"
        />
      </form>
    </>
  );
};

export const CreateUrlForm = ({ className }) => {
  return (
    <form className={`${className} form`}>
      <section className={`${className}__fields`}>
        <label>URL</label>
        <Input className="input input--large" />
        <label>Project</label>
        <select className="input input--small">
          {/* Mappen over projecten */}
          <option>Project 1</option>
          <option>Project 2</option>
          <option>Project 3</option>
        </select>
      </section>
      <section className={`${className}__buttons`}>
        <Button className="btn btn--cancel" name="Cancel" />
        <Button className="btn btn--primary" name="Create" />
      </section>
    </form>
  );
};

export const CreateUserForm = ({ className }) => {
  return (
    <form className={`${className} form`}>
      <section className={`${className}__fields`}>
        <section>
          <label>Mail</label>
          <Input className="input input--small" />
        </section>
        <section>
          <label>Password</label>
          <Input className="input input--small" />
        </section>
        <section>
          <label>Role</label>
          <select className="input input--small">
            {/* Mappen over rollen */}
            <option>Rol 1</option>
            <option>Rol 2</option>
            <option>Rol 3</option>
          </select>
        </section>
      </section>
      <section className={`${className}__buttons`}>
        <Button className="btn btn--cancel" name="Cancel" />
        <Button className="btn btn--primary" name="Create" />
      </section>
    </form>
  );
};

export const EditUserForm = ({ className }) => {
  return (
    <form className={`${className} form`}>
      <section className={`${className}__fields`}>
        <section>
          <h2>Edit profile</h2>
          <div className={`${className}__fields__content`}>
            <div className={`${className}__fields__item`}>
              <label>Mail</label>
              <Input className="input input--small" />
            </div>
            <div className={`${className}__fields__item`}>
              <label>Role</label>
              <select className="input input--small">
                {/* Mappen over rollen */}
                <option>Rol 1</option>
                <option>Rol 2</option>
                <option>Rol 3</option>
              </select>
            </div>
          </div>
        </section>
        <section>
          <h2>Change password</h2>
          <div className={`${className}__fields__item`}>
            <label>Confirm password</label>
            <Input className="input input--small" />
          </div>
          <div className={`${className}__fields__content`}>
            <div className={`${className}__fields__item`}>
              <label>New assword</label>
              <Input className="input input--small" />
            </div>
            <div className={`${className}__fields__item`}>
              <label>Confirm new password</label>
              <Input className="input input--small" />
            </div>
          </div>
        </section>
      </section>
      <section className={`${className}__buttons`}>
        <Button className="btn btn--secondary" name="Delete" />
        <Button className="btn btn--cancel" name="Cancel" />
        <Button className="btn btn--primary" name="Create" />
      </section>
    </form>
  );
};

export const Form = ({
  type,
  title,
  children,
  buttons,
  ondelete,
  oncreate,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = data => console.log(data);

  const formAction = action => {
    if (action === "Delete") ondelete && onDelete();
    if (action === "Create") oncreate && onCreate();
    else goBack();
  };

  const onCreate = () => {
    return alert("aangemaakt");
  };

  const onDelete = () => {
    return alert("verwijderd");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      {title && <h2>{title}</h2>}
      <section className={`form__fields`}>{children}</section>
      <section className={`form__buttons`}>
        {buttons?.map(button => {
          return (
            <span key={button} className={button.toLowerCase()}>
              <Button
                type="button"
                onClick={() => formAction(button)}
                className={`btn btn--${
                  button === "Create" || button === "Update"
                    ? "primary"
                    : button === "Delete"
                    ? "secondary"
                    : "cancel"
                }`}
                name={button}
              />
            </span>
          );
        })}
      </section>
    </form>
  );
};
