import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";

import { CardItem } from "../components/atoms/CardItem";
import { BackgroundDashboard2 } from "../components/atoms/Svg";

import { Layout } from "../components/molecules/Layout";
import { projectsurl } from "../api";

// TODO
// Sort van slechtse naar beste projecten, refresh om x aantal sec

export const Dashboard2 = () => {
  const [projects, setProjects] = useState([]);
  const URL = projectsurl+"&active=1";
  const [userid] = useState(Cookies.get("userid").split("/")[3]);

  useEffect(() => {
        Cookies.get("role").split(",").includes("ROLE_ADMIN")
          ? axios
              .get(URL)
              .then(result => setProjects(result.data["hydra:member"]))
              .catch(error => console.log(error))
          : axios
              .get(URL+'&user='+userid)
              .then(result => setProjects(result.data["hydra:member"]))
              .catch(error => console.log(error));
  }, []);

  return (
    <>
      <Layout
        headerName="dashboard 2"
        headerType="minimal"
        className="dashboard2"
      >
        {projects.map(e => (
          <Link to={"/projects/" + e["@id"].split("/")[3]}>
            <CardItem
              key={e.name}
              name={e.name}
              logo={e.logo}
              mobile={Math.round(
                e?.mainurl_id?.lastMobileResult?.scores[6]?.valueProcent
              )}
              desktop={Math.round(
                e?.mainurl_id?.lastDesktopResult?.scores[6]?.valueProcent
              )}
            />
          </Link>
        ))}
      </Layout>
      <BackgroundDashboard2 className="dashboard2__bg" />
    </>
  );
};
