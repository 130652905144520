import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Cookies from "js-cookie";

export const MenuItem = ({ name, path, img }) => {
  const navigate = useNavigate();

  const nav = () => {
    navigate(path);
  };

  const action = () => {
    Cookies.remove("bearer_token");
    Cookies.remove("refresh_token");
    Cookies.remove("email");
    Cookies.remove("userid");
    Cookies.remove("role");

    navigate("/");
  };

  return (
    <div onClick={path ? nav : action} className="card menucard">
      <div className="card__img">
        <img alt={img} src={img}></img>
      </div>
      <div className="card__tag">
        <p>{name}</p>
      </div>
      <div className={"menucard__inactive"}></div>
    </div>
  );
};
