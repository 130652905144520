import React, { useEffect, useState } from "react";
import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";
import { Input } from "../components/atoms/Input";
import { CeateUrlBackground } from "../components/atoms/Svg";
import { Button } from "../components/atoms/Button";
import { useForm } from "react-hook-form";
import { addSynthetic, editSynthetic, projectdetail, syntheticJobDetail } from "../api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation, Link } from "react-router-dom";

export const EditJob = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [jobid, setJobid] = useState(location.pathname.split("/")[2]);
  const [token] = useState(Cookies.get("bearer_token"));
  const [syntheticjob, setSyntheticjob] = useState([]);

  useEffect(() => {
    axios
      .get(syntheticJobDetail(jobid))
      .then(response => {
        setSyntheticjob(response.data);
      })
      .catch(error => console.log(error));
  }, [jobid]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = data => {
    console.log(data);
    axios
      .post(
        editSynthetic(jobid),
        JSON.stringify({
          name: data.Name,
          description: data.Description,
          codescript: data.Script,
        }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        console.log(res);
        alert("Succesvol aangepast!");
        navigate(-1);
      })
      .catch(error => {
        console.log(error);
      });
  };
    setValue("Name", syntheticjob?.synthetic_job?.name);
    setValue("Description", syntheticjob?.synthetic_job?.description);
    setValue("Script", syntheticjob?.code);
  return (
    <>
      <Layout headerName={"Edit Synthetic Job"} className={"createUrl"}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <section className="form__fields">
            <Input label="Name" placeholder="Job name" register={register} />
            <Input
              label="Description"
              placeholder="Job description"
              register={register}
            ></Input>

            <Input
              label="Script"
              large
              placeholder="Paste script here"
              area={"yes"}
              register={register}
            />
          </section>
          <section className="form__buttons">
            <span>
              <Button type="submit" className="btn btn--primary" name="Save" />
            </span>
          </section>
        </form>
      </Layout>
      <CeateUrlBackground className="CeateUrlBackground" />
    </>
  );
};
