import { Input } from "../components/atoms/Input";
import { Button } from "../components/atoms/Button";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";
import axios from "axios";

import { config } from "../helpers/config";

import {
  AddProjectBackground1,
  AddProjectBackground2,
} from "../components/atoms/Svg";
import { useEffect, useState } from "react";
import { projectsurl, projectsurlpost, projectype } from "../api";

export const AddProject = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState([]);
  const [projectType, setProjecttype] = useState([]);
  useEffect(() => {
    axios
      .get(projectype)
      .then(response => {
        setProjecttype(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onImageChange = e => {
    setImages(e.target.files[0]);
  };
  useEffect(() => {
    if (images.length < 1) return;
    const object = URL.createObjectURL(images);
    setPreview(object);
  }, [images]);
  const onSubmit = data => {
    console.log(data);
    let numWeight = Number(data.Weight);
    // console.log(
    //   JSON.stringify({
    //     name: data.Name,
    //     weight: numWeight,
    //     mainUrl: data.MainURL,
    //     updateFileName: "test",
    //     logo: "test",
    //     active: true,
    //     projectType: data.ProjectType,
    //   })
    // );

    axios
      .post(
        projectsurlpost,
        JSON.stringify({
          name: data.Name,
          weight: numWeight,
          mainUrl: data.MainURL,
          updateFileName: "test",
          logo: preview,
          active: Boolean(data.State),
          projectType: data.ProjectType,
        }),
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(res => {
        console.log(res);
        alert("Succesvol toegevoegd!");
        navigate(-1);
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      <Layout headerName="Add project" className="projectform">
        <form
          className="form"
          onSubmit={handleSubmit(onSubmit)}
          // buttons={["cancel", "Create"]}
          // oncreate={() => console.log("clicked")}
        >
          <section className="form__fields">
            <Input
              label="Name"
              placeholder="Project name"
              register={register}
            />
            {projectType && projectType[0] && (
              <Input
                def={projectType[0]["@id"]}
                label="ProjectType"
                select
                register={register}
              >
                {projectType.map(e => (
                  <option key={e.type} value={e["@id"]}>
                    {e.type}
                  </option>
                ))}
              </Input>
            )}

            <Input label="User" select register={register} def="Admin">
              {config.users().map(e => (
                <option key={e}>{e}</option>
              ))}
            </Input>
            <Input
              label="Weight"
              type={"number"}
              placeholder="69MB"
              register={register}
            />
            <section className="projectform__logoAndPreview fullWidth">
              <Input label="State" select register={register} def="Active">
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </Input>
              <section>
                <section className="halfWidth">
                  <label>Logo</label>

                  <label className="btn btn--primary input-logo">
                    upload file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                  </label>
                </section>
                <section className="halfWidth">
                  <label>Preview</label>
                  {/* LOGO */}
                  {console.log(preview)}
                  {images && <img src={preview} />}
                </section>
              </section>
            </section>
            <Input
              label="MainURL"
              large
              placeholder="https://main-url"
              register={register}
            />
          </section>
          <section className="form__buttons">
            <span>
              <Button
                type="submit"
                className="btn btn--primary"
                name="Create"
              />
            </span>
          </section>
        </form>
      </Layout>

      <AddProjectBackground2 className="projectBackground2" />
    </>
  );
};
// export const Form = ({
//   type,
//   title,
//   children,
//   buttons,
//   ondelete,
//   oncreate,
// }) => {
//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors },
//   } = useForm();

//   const navigate = useNavigate();
//   const onSubmit = data => console.log(data);

//   const formAction = action => {
//     if (action === "Delete") ondelete && onDelete();
//     if (action === "Create") oncreate && onCreate();
//     else goBack();
//   };

//   const onCreate = () => {
//     return alert("aangemaakt");
//   };

//   const onDelete = () => {
//     return alert("verwijderd");
//   };

//   const goBack = () => {
//     navigate(-1);
//   };

//   return (
//     <form className="form" onSubmit={handleSubmit(onSubmit)}>
//       {title && <h2>{title}</h2>}
//       <section className={`form__fields`}>{children}</section>
//       <section className={`form__buttons`}>
//         {buttons?.map(button => {
//           return (
//             <span key={button} className={button.toLowerCase()}>
//               <Button
//                 type="button"
//                 onClick={() => formAction(button)}
//                 className={`btn btn--${
//                   button === "Create" || button === "Update"
//                     ? "primary"
//                     : button === "Delete"
//                     ? "secondary"
//                     : "cancel"
//                 }`}
//                 name={button}
//               />
//             </span>
//           );
//         })}
//       </section>
//     </form>
//   );
// };
