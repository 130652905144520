import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { CgScreen, CgSmartphone } from "react-icons/cg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grid } from "react-loader-spinner";
// import { Grid } from '@splidejs/splide-extension-grid';

import axios from "axios";
import Chart from "chart.js/auto";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import { get } from "../helpers/get";
import Cookies from "js-cookie";

import { Button } from "../components/atoms/Button";
import { Toggle } from "../components/atoms/Toggle";
import { Linechart } from "../components/atoms/Linechart";
import { BackgroundDashboard } from "../components/atoms/Svg";

import { Layout } from "../components/molecules/Layout";
import { ProjectCard } from "../components/molecules/Projectcard";
import { projectsurl, worsturlsproject, worsturlsurl } from "../api";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [worsturls, setWorsturls] = useState([]);
  const [toggleView, setToggleView] = useState("1");
  const [userid] = useState(Cookies.get("userid").split("/")[3]);

  useEffect(() => {
    Cookies.get("role").split(",").includes("ROLE_ADMIN")
      ? axios
          .get(projectsurl + "&active=1")
          .then(response => {
            setProjects(response.data["hydra:member"]);
          })
          .catch(error => console.log(error))
      : axios
          .get(projectsurl + "&active=1&user=" + userid)
          .then(response => {
            setProjects(response.data["hydra:member"]);
            setProject(response.data["hydra:member"][0]);
          })
          .catch(error => console.log(error));

    // axios.get("https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://mediasoft.be&key=AIzaSyBbwMmuQHs0dWZWeXpy7agE_zJFdLFHHEU")
    //     .then(response => console.log(response))
  }, [projects]);
  useEffect(() => {
    projects && Cookies.get("role").split(",").includes("ROLE_ADMIN")
      ? axios
          .get(worsturlsurl)
          .then(response => {
            setWorsturls(response.data["hydra:member"]);
          })
          .catch(error => console.log(error))
      : axios
          .get(worsturlsproject(project["@id"]))
          .then(response => {
            setWorsturls(response.data["hydra:member"]);
          })
          .catch(error => console.log(error));
  }, [projects]);
  return (
    <>
      <Layout headerName="dashboard" headerType="minimal" className="dashboard">
        {(!projects.length == 0) & (worsturls.length !== 0) ? (
          <div>
            <section className="dashboard-upper">
              <div className="performance__card">
                <section className="pages">
                  <h3>Pages</h3>
                  <div className="performance__pages">
                    <div className="performance__pages__content boxshadow">
                      <br></br>
                      {Cookies.get("role").split(",").includes("ROLE_ADMIN") ? (
                        <Linechart />
                      ) : (
                        <Linechart projectid={project["@id"].split("/")[3]} />
                      )}
                    </div>
                  </div>
                </section>
                <section className="performance">
                  <h3>Worst performance</h3>

                  <div>
                    <div className="performance__content boxshadow">
                      <div className="performance__content__stats">
                        <div className="statsscores">
                          <FiAlertTriangle className="icon icon--error" />
                        </div>
                      </div>
                      <div className="performance__box">
                        <div className="performance__box__sites">
                          {worsturls.map(link => (
                            <div
                              key={link.url}
                              className="performance__box__container"
                            >
                              <div className="performance__sites">
                                <p>{link.url}</p>
                              </div>
                              <div className="performance__sites">
                                <p
                                  style={{
                                    color: get.color(
                                      link.lastPerformanceResult * 100
                                    ),
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                  }}
                                >
                                  {Cookies.get("role")
                                    .split(",")
                                    .includes("ROLE_ADMIN")
                                    ? Math.round(
                                        link.lastPerformanceResult * 100
                                      )
                                    : Math.round(link.value * 100)}
                                  %
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>

            <section className="dashboard-lower">
              <section className="project">
                <h3>Projects</h3>
                <div>
                  {Cookies.get("role").split(",").includes("ROLE_ADMIN") ? (
                    <Splide
                      options={{
                        type: "loop",
                        gap: "1rem",
                        autoplay: false,
                        pauseOnHover: false,
                        resetProgress: false,
                        arrows: "slider",
                        perPage: 3,
                        breakpoints: {
                          1300: {
                            perPage: 2,
                          },
                          750: { destroy: true },
                        },
                        classes: {
                          // Add classes for arrows.
                          arrow: "splide__arrow svg",
                          // prev  : 'splide__arrow--prev your-class-prev',
                          // next  : 'splide__arrow--next your-class-next',
                        },
                        // grid: {
                        //   rows: 3,
                        //   cols: 2,
                        //   gap: {
                        //     row: "1rem",
                        //     col: "1.5rem",
                        //   },
                        // },
                      }}
                    >
                      {projects.map(item => (
                        <SplideSlide>
                          <ProjectCard item={item} />
                        </SplideSlide>
                      ))}
                    </Splide>
                  ) : (
                    projects.map(item => (
                      <div className="projectcard_grid">
                        <ProjectCard item={item} />
                      </div>
                    ))
                  )}
                </div>
              </section>
            </section>
          </div>
        ) : (
          <div class="center-screen">
            <Grid
              height="100"
              width="100"
              color="#0A006F"
              ariaLabel="loading"
            />
          </div>
        )}
      </Layout>
      <BackgroundDashboard className="dashboard_background" />
    </>
  );
};
