import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import { CgScreen, CgSmartphone } from "react-icons/cg";

import axios from "axios";

import { Toggle } from "../atoms/Toggle";
import { Gauge } from "../atoms/Gauge";
import { get } from "../../helpers/get";
import { worsturlsproject } from "../../api";

export const ProjectCard = ({ item }) => {
  const [toggleView, setToggleView] = useState();
  const [projectlinks, setProjectlinks] = useState([]);
  const projectdetailApi = item["@id"].split("/");
  useEffect(() => {
    if (!toggleView) {
      setToggleView("1");
    }
  }, []);
  useEffect(() => {
    axios
      .get(worsturlsproject(item["@id"]))
      .then(response => {
        setProjectlinks(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, []);

  // Gives the correct color to the icons
  let colorMobile = "card__mobile ";
  let textMobile = "card__text ";
  let colorDesktop = "card__desktop ";
  let textDesktop = "card__text ";

  const color = score => {
    return score < 50 ? "red" : score >= 90 ? "green" : "orange";
  };

  const cards = (m, d) => {
    colorMobile += `test card__mobile--${color(m)}`;
    textMobile += `card__mobile--${color(m)}`;

    colorDesktop += `test card__mobile--${color(d)}`;
    textDesktop += `card__mobile--${color(d)}`;
  };
  const mob =
    item && item.homepageTcpMobile && item.homepageTcpMobile.valueProcent
      ? item.homepageTcpMobile.valueProcent
      : null;
  const desk =
    item && item.homepageTcpDesktop && item.homepageTcpDesktop.valueProcent
      ? item.homepageTcpDesktop.valueProcent
      : null;
  cards(mob, desk);

  return (
    <div>
      <Link to={"/" + projectdetailApi[2] + "/" + projectdetailApi[3]}>
        <div className="project__header">
          {item.logo ? (
            item.logo.slice(0, 4) === "blob" ? (
              <div>
                <img src={item.logo}></img>
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: item.logo }}></div>
            )
          ) : (
            <h3>{item.name}</h3>
          )}
        </div>
      </Link>

      <div className="project__content boxshadow">
        <div className="project__content__stats">
          <FiAlertTriangle className="icon icon--error" />
          <div className="statsscores">
            <div className="statsscores__mobiledesktop">
              <CgSmartphone
                className={colorMobile}
                style={{
                  color: `${get.color(
                    item?.mainurl_id?.lastMobileResult?.scores[6]?.valueProcent
                  )}`,
                }}
              />
              {item?.mainurl_id?.lastMobileResult?.scores[6]?.valueProcent ? (
                <p
                  className={textMobile}
                  style={{
                    color: `${get.color(
                      item?.mainurl_id?.lastMobileResult?.scores[6]
                        ?.valueProcent
                    )}`,
                  }}
                >
                  {Math.round(
                    item?.mainurl_id?.lastMobileResult?.scores[6]?.valueProcent
                  )}
                  %
                </p>
              ) : null}{" "}
            </div>
            <div className="statsscores__mobiledesktop">
              <CgScreen
                className={colorDesktop}
                style={{
                  color: `${get.color(
                    item?.mainurl_id?.lastDesktopResult?.scores[6]?.valueProcent
                  )}`,
                }}
              />
              {item?.mainurl_id?.lastDesktopResult?.scores[6]?.valueProcent ? (
                <p
                  className={textDesktop}
                  style={{
                    color: `${get.color(
                      item?.mainurl_id?.lastDesktopResult?.scores[6]
                        ?.valueProcent
                    )}`,
                  }}
                >
                  {Math.round(
                    item?.mainurl_id?.lastDesktopResult?.scores[6]?.valueProcent
                  )}
                  %
                </p>
              ) : null}{" "}
            </div>
          </div>
          <div performance__btn>
            <Toggle
              value={toggleView}
              name={item.name}
              values={[
                { label: "Specs", view: 1 },
                { label: "Pages", view: 2 },
              ]}
              onClick={e => setToggleView(e)}
            ></Toggle>
          </div>
        </div>
        <div className="project__box">
          {toggleView === "2" ? (
            <div className="project__box__sites">
              {projectlinks.map(link => (
                <div key={link.url} className="project__box__container">
                  <div className="project__sites">
                    <p>{link.url}</p>
                  </div>
                  <div className="project__sites">
                    <p
                      style={{
                        color: get.color(link.value * 100),
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      {Math.round(link.value * 100)} %
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="charts">
              <div>
                <div className="project__box__performance">
                  <Gauge
                    value={
                      item?.mainurl_id?.lastMobileResult?.scores[6]
                        ?.valueProcent
                    }
                  ></Gauge>
                  <p className="gauge__text">Performance</p>
                </div>
                <div className="project__box__performance">
                  <Gauge
                    value={
                      item?.mainurl_id?.lastMobileResult?.scores[8]
                        ?.valueProcent
                    }
                  ></Gauge>
                  <p className="gauge__text">SEO</p>
                </div>
              </div>
              <div>
                <div className="project__box__performance">
                  <Gauge
                    value={
                      item?.mainurl_id?.lastMobileResult?.scores[7]
                        ?.valueProcent
                    }
                  ></Gauge>
                  <p className="gauge__text">Accecibility</p>
                </div>
                <div className="project__box__performance">
                  <Gauge
                    value={
                      item?.mainurl_id?.lastMobileResult?.scores[10]
                        ?.valueProcent
                    }
                  ></Gauge>
                  <p className="gauge__text">Best Practices</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
