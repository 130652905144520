export const Toggle = ({ value, values, onClick, name }) => {
  return (
    <div className="toggle">
      {values.map(v => {
        const active = `${v.view}` === value;

        return (
          <span key={v.label}>
            <input
              onClick={e => onClick(e.target.value)}
              id={name ? "toggle-on" + name + v.label : "toggle-on" + v.label}
              className="toggle__input"
              name={name ? "toggle" + name : "toggle"}
              value={`${v.view}`}
              type="radio"
            />
            <label
              for={name ? "toggle-on" + name + v.label : "toggle-on" + v.label}
              className={`${active && "active"} toggle__header`}
            >
              {v.label}
            </label>
          </span>
        );
      })}
    </div>
  );
};