import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiInfo } from "react-icons/fi";
import { Oval } from "react-loader-spinner";

import { get } from "../../helpers/get";

export const Metrics = ({ name, content, colorCard }) => {
  const [metrix, setMetrix] = useState([]);
  const [link, setLink] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(link).then(response => {
      setMetrix(response.data.lighthouseResult.audits.metrics.details.items[0]);
      setLoading(false);
    });
  }, [link]);
  return (
    <div className="projectmetrics__box boxshadow">
      <h3>
        {!content ? (
          <Oval height="20" width="20" color="#0A006F" ariaLabel="loading" />
        ) : (
          content
        )}
      </h3>
      <p>{name}</p>
      <FiInfo className="projectmetrics__box__icon" />
      <div
        className={`projectmetrics__box__corner`}
        style={{ backgroundColor: colorCard }}
      ></div>
    </div>
  );
};
