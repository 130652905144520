import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiInfo } from "react-icons/fi";
import { Oval } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";

import { get } from "../../helpers/get";

export const Performance = ({ name, value, tip, onClick, style }) => {
  return (
    <div
      style={{ backgroundColor: style && "#0a006f" }}
      onClick={() => onClick(name)}
      className="projectperformance__box boxshadow"
    >
      <h3 style={{ color: style && "#fff" }}>{value}</h3>
      <p style={{ color: style && "#fff" }}>{name}</p>
      <ReactTooltip id="performanceTip" place="top" effect="solid">
        {tip}
      </ReactTooltip>
      <FiInfo
        style={{ color: style && "#fff" }}
        data-tip
        data-for="performanceTip"
        className="projectperformance__box__icon"
      />
      <div
        style={{
          backgroundColor: get.color(value),
        }}
        className={"projectperformance__box__corner"}
      ></div>
    </div>
  );
};
