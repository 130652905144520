import { Form } from "../components/molecules/Form";
import { Layout } from "../components/molecules/Layout";
import { Input } from "../components/atoms/Input";
import {
  CeateUserBackgroundUpper,
  CeateUserBackgroundLower,
} from "../components/atoms/Svg";

export const CreateUser = () => {
  return (
    <>
      <Layout className="userform" headerName="Create new User">
        {/* <CreateUserForm className="createuser"/> */}
        <Form
          buttons={["Cancel", "Create"]}
          onCreate={() => console.log("aangemaakt!")}
        >
          <Input label="Mail" />
          <Input label="Pasword" type="password" />
          <Input label="Role" select>
            <option>Rol 1</option>
            <option>Rol 2</option>
            <option>Rol 3</option>
          </Input>
        </Form>
      </Layout>
      <CeateUserBackgroundUpper className="createUserUpper" />
      <CeateUserBackgroundLower className="createUserLower" />
    </>
  );
};
