import React, { useEffect, useState } from "react";
import axios from "axios";
import { Oval } from "react-loader-spinner";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { totalPages, totalPagesNoId } from "../../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {},
    title: {
      display: false,
      text: "Badpages",
    },
  },
};

export const Linechart = ({ projectid }) => {
  const [badpages, setBadpages] = useState([]);
  const [mediumpages, setMediumpages] = useState([]);
  const [goodpages, setGoodpages] = useState([]);

  const [link, setLink] = useState();
  const [link2, setLink2] = useState();
  const [link3, setLink3] = useState();
  //TODO 1 DYNAMISCHE LINK
  useEffect(() => {
    if (projectid != undefined) {
      setLink(
        totalPages({
          type: "TCP",
          strategy: "mobile",
          lower: 0,
          higher: 0.5,
          projectid: projectid,
        })
      );
    } else {
      setLink(
        totalPagesNoId({
          type: "TCP",
          strategy: "mobile",
          lower: 0,
          higher: 0.5,
        })
      );
    }
  }, []);
  useEffect(() => {
    if (projectid != undefined) {
      setLink2(
        totalPages({
          type: "TCP",
          strategy: "mobile",
          lower: 0.5,
          higher: 0.9,
          projectid: projectid,
        })
      );
    } else {
      setLink2(
        totalPagesNoId({
          type: "TCP",
          strategy: "mobile",
          lower: 0.5,
          higher: 0.9,
        })
      );
    }
  }, []);
  useEffect(() => {
    if (projectid != undefined) {
      setLink3(
        totalPages({
          type: "TCP",
          strategy: "mobile",
          lower: 0.9,
          higher: 1,
          projectid: projectid,
        })
      );
    } else {
      setLink3(
        totalPagesNoId({
          type: "TCP",
          strategy: "mobile",
          lower: 0.9,
          higher: 1,
        })
      );
    }
  }, []);
  useEffect(() => {
    link &&
      axios
        .get(link)
        .then(response => {
          setBadpages(response.data);
        })
        .catch(error => console.log(error));
  }, [link]);
  useEffect(() => {
    link2 &&
      axios
        .get(link2)
        .then(response => {
          setMediumpages(response.data);
        })
        .catch(error => console.log(error));
  }, [link2]);
  useEffect(() => {
    link3 &&
      axios
        .get(link3)
        .then(response => {
          setGoodpages(response.data);
        })
        .catch(error => console.log(error));
  }, [link3]);
  const data = {
    labels: badpages.labels,
    datasets: [
      {
        label: "Bad",
        data: badpages.data,
        borderColor: "#e776a2",
        backgroundColor: "#e776a2",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Medium",
        data: mediumpages.data,
        borderColor: "#FFB549",
        backgroundColor: "#FFB549",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Good",
        data: goodpages.data,
        borderColor: "#78E0B5",
        backgroundColor: "#78E0B5",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  return <Line height="130px" options={options} data={data} />;
};
