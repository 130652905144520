import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";
import { Input } from "../components/atoms/Input";
import { CeateUrlBackground } from "../components/atoms/Svg";

export const CreateURL = () => {
  return (
    <>
      <Layout headerName={"Create URL"} className={"createUrl"}>
        <Form buttons={["Cancel", "Create"]}>
          <Input label="URL" large />
          <Input label="Project" select>
            <option>Project 1</option>
            <option>Project 2</option>
            <option>Project 3</option>
          </Input>
        </Form>
      </Layout>
      <CeateUrlBackground className="CeateUrlBackground" />
    </>
  );
};
