export const Input = ({
  type,
  id,
  onChange,
  label,
  large,
  empty = "",
  select,
  children,
  placeholder,
  register,
  def,
  value,
  area
}) => {
  return (
    <section
      className={`form__item ${!large && "form__item--half"} ${
        empty === "right"
          ? "form__item--emptyRight"
          : empty === "left"
          ? "form__item--emptyLeft"
          : ""
      }`}
    >
      {console.log(def)}
      {label && <label HTMLfor={id}>{label}</label>}

      {area === "yes" ? (
        <textarea
          style={{
            height:250
          }}
          name={label}
          type={type}
          placeholder={placeholder}
          className={`input`}
          id={id}
          onChange={onChange}
          {...register(label)}
        ></textarea>
      ) : select ? (
        <select
          defaultValue={def}
          name={label}
          className="input input--small"
          onChange={onChange}
          {...register(label)}
        >
          {children}
        </select>
      ) : register ? (
        <input
          name={label}
          type={type}
          placeholder={placeholder}
          className={`input`}
          id={id}
          onChange={onChange}
          {...register(label)}
        />
      ) : (
        <input
          name={label}
          type={type}
          placeholder={placeholder}
          className={`input`}
          id={id}
          onChange={onChange}
        />
      )}
    </section>
  );
};
