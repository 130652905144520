import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grid } from "react-loader-spinner";
import { Button } from "../components/atoms/Button";
import { Layout } from "../components/molecules/Layout";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { deleteSyntheticJob, deploy, dev, runSyntheticJob, syntheticJobDetail } from "../api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const JobDetail = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [jobid, setJobid] = useState(location.pathname.split("/")[2]);
  const [syntheticdetail, setSyntheticdetail] = useState();
  const [token] = useState(Cookies.get("bearer_token"));
  useEffect(() => {
    axios
      .get(syntheticJobDetail(jobid), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        setSyntheticdetail(response.data);
      })
      .catch(error => console.log(error));
  }, [jobid]);
  console.log(syntheticdetail);
  const deletejob = (id) => {
    axios
      .delete(deleteSyntheticJob(id), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res);
        alert("Succesvol verwijderd!");
        navigate(-1);
      });
  };
  const runjob = id => {
    axios
      .get(runSyntheticJob(id), {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res);
        alert("Succesvol gedraaid!");
        window.location.reload()
      });
  };
  return (
    <>
      <Layout
        headerName={syntheticdetail?.synthetic_job?.name}
        className="jobdetail"
      >
        <section className="projecttop">
          <div className="projecttop__scores"></div>

          <div className="projecttop__buttons">
            <Button
              className="btn btn--play projecttop__buttons__btn projecttop__buttons__edit"
              icon="play"
              onClick={() => runjob(jobid)}
            />
            <Button
              className="btn btn--primary projecttop__buttons__btn projecttop__buttons__edit"
              icon="edit"
              // onClick={() => navigate("editproject")}
              path={`editsyntheticform/${jobid}`}
            />
            <Button
              className="btn btn--secondary projecttop__buttons__btn"
              icon="trash"
              onClick={() => deletejob(jobid)}
            />
          </div>
        </section>
        <br />
        <section className="jobdetail-top">
          <section
            className={`jobdetail-top__pass jobdetail-top__pass${
              syntheticdetail?.output?.status === "pass" ? "--green" : "--red"
            } card`}
          >
            <h4>{syntheticdetail?.output?.status}</h4>
          </section>
          <section className="jobdetail-top__info card">
            <h4>file name: {syntheticdetail?.synthetic_job?.fileName}</h4>
          </section>
          <section className="jobdetail-top__seconds card">
            <h4>Time: {parseFloat(syntheticdetail?.output?.time / 1000)} s</h4>
          </section>
          <section className="jobdetail-top__logs card">
            <h4>Logs</h4>
            {syntheticdetail?.output?.output.map(log => {
              return (
                <h5
                  key={log.output}
                >{`[${log.type}] - ${log.datetime} - ${log.output} `}</h5>
              );
            })}
          </section>
        </section>
        <section className="jobdetail-bottom">
          <h3>Screenshots</h3>
          <section className="jobdetail-bottom__screenshots">
            {syntheticdetail &&
              Object.entries(syntheticdetail?.images).map(([key, value]) => {
                return (
                  <img
                    key={value}
                    className="jobimage card"
                    src={`${deploy + "/" + syntheticdetail?.relative + value}`}
                  />
                );
              })}
          </section>
        </section>
      </Layout>
    </>
  );
};
