import { Layout } from "../components/molecules/Layout";
import { Form } from "../components/molecules/Form";

import { Input } from "../components/atoms/Input";

import {
  CreateScoreTypeBackground1,
  CreateScoreTypeBackground2,
} from "../components/atoms/Svg";

export const CreateScoreType = () => {
  return (
    <>
      <Layout className="createScoreType" headerName="Create new ScoreType">
        <Form buttons={["Cancel", "Create"]}>
          <Input label="Type" />
          <Input label="Unit" />
          <Input label="Description" />
        </Form>
      </Layout>
      <CreateScoreTypeBackground1 className="createScoretypeBackground1" />
      <CreateScoreTypeBackground2 className="createScoretypeBackground2" />
    </>
  );
};
