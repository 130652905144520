import React, { useEffect, useState } from "react";
import { Grid } from "react-loader-spinner";
import { Link } from "react-router-dom";

import { Layout } from "../components/molecules/Layout";
import { Button } from "../components/atoms/Button";
import { Sitemap } from "../components/atoms/Sitemap";
import { siteurlsurl } from "../api";

import axios from "axios";
import {
  SitemapsBackground,
  SitemapsBackground2,
  SitemapsBackground3,
} from "../components/atoms/Svg";

export const Sitemaps = () => {
  const [siteurls, setSiteurls] = useState([]);
  useEffect(() => {
    axios
      .get(siteurlsurl)
      .then(response => {
        setSiteurls(response.data["hydra:member"]);
      })
      .catch(error => console.log(error));
  }, []);
  return (
    <>
      <Layout headerName="Sitemap" className="projects">
        {siteurls.length != 0 ? (
          <>
            <Button
              className="btn btn--primary projects__add"
              icon="plus"
              path={"createurl"}
            />
            <section className="projectsitemap">
              <h2>Sitemap</h2>
              <div className="projectsitemap__headings">
                <div className="projectsitemap__headings__urls">Urls</div>
                <div className="projectsitemap__headings__mob">
                  Mobile score
                </div>
                <div className="projectsitemap__headings__desk">
                  Desktop score
                </div>
                <div className="projectsitemap__headings__error">H1 errors</div>
                <div className="projectsitemap__headings__empty"></div>
              </div>
              <section className="projectsitemap__boxcontainer">
                {siteurls?.map(e => (
                  <Link to={"/siteurlDetail/" + e["@id"].split("/")[3]}>
                    <Sitemap e={e} />
                  </Link>
                ))}
              </section>
            </section>
          </>
        ) : (
          <div className="center-screen">
            <Grid
              height="100"
              width="100"
              color="#0A006F"
              ariaLabel="loading"
            />
          </div>
        )}
      </Layout>

      <SitemapsBackground className="sitemap_background"></SitemapsBackground>
      <SitemapsBackground2 className="sitemap_background2"></SitemapsBackground2>
      <SitemapsBackground3 className="sitemap_background3"></SitemapsBackground3>
    </>
  );
};
