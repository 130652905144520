import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { Dashboard2 } from "./pages/Dashboard2";
import { Menu } from "./components/molecules/Menu";
import { Projects } from "./pages/Projects";
import { Project } from "./pages/Project";
import { AddProject } from "./pages/AddProject";
import { AddJob } from "./pages/AddJob";
import { CreateURL } from "./pages/CreateURL";
import { CreateUser } from "./pages/CreateUser";
import { EditUser } from "./pages/EditUser";
import { EditScoreType } from "./pages/EditScoreType";
import { CreateScoreType } from "./pages/CreateScoreType";
import { EditProject } from "./pages/EditProject";
import { EditJob } from "./pages/EditJob";

import "./style/main.scss";
import { Sitemaps } from "./pages/Sitemaps";
import { SiteurlDetail } from "./pages/SiteurlDetail";
import { JobProjects } from "./pages/JobProjects";
import { JobList } from "./pages/JobList";
import { JobDetail } from "./pages/JobDetail";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>

        <Route exact path="/dashboard" element={<Dashboard />}></Route>

        <Route exact path="/dashboard2" element={<Dashboard2 />}></Route>

        <Route exact path="/menu" element={<Menu />}></Route>

        <Route
          exact
          path="/projects"
          element={<Projects path={"projects"} />}
        ></Route>

        <Route exact path="/projects/:id" element={<Project />}></Route>

        <Route
          exact
          path="/projects/:id/editproject"
          element={<EditProject />}
        ></Route>

        <Route exact path="/projectform" element={<AddProject />}></Route>

        <Route exact path="/syntheticform/:id" element={<AddJob />}></Route>
        <Route exact path="/editsyntheticform/:id" element={<EditJob />}></Route>

        <Route exact path="/createurl" element={<CreateURL />}></Route>

        <Route exact path="/sitemaps" element={<Sitemaps />}></Route>

        <Route exact path="/createuser" element={<CreateUser />}></Route>

        <Route
          exact
          path="/siteurlDetail/:id"
          element={<SiteurlDetail />}
        ></Route>

        <Route exact path="/edituser" element={<EditUser />}></Route>

        <Route exact path="/editscoretype" element={<EditScoreType />}></Route>

        <Route
          exact
          path="/createscoretype"
          element={<CreateScoreType />}
        ></Route>
        <Route exact path="/jobs" element={<JobProjects />}></Route>
        <Route exact path="/jobs/:id" element={<JobList />}></Route>
        <Route exact path="/jobs/:id/detail" element={<JobDetail />}></Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root") // gaat kijken in de base.twig naar een element met id root
  // als hij deze heeft gevonden gaat hij deze inladen en uitvoeren.
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
