import React, { useEffect, useState } from "react";
import { get } from "../../helpers/get";
import axios from "axios";

import {
  FiAlertTriangle,
  FiInfo,
  FiChevronDown,
  FiChevronUp,
  FiCheckCircle,
} from "react-icons/fi";
import { CgScreen, CgSmartphone } from "react-icons/cg";
import { Button } from "./Button";
import { errorstitle } from "../../api";

export const Sitemap = ({ e, project }) => {
  const siteUrlId = e["@id"].split("/");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState();
  useEffect(() => {
    axios
      .get(errorstitle(siteUrlId[3]))
      .then(response => {
        setErrors(response.data);
      })
      .catch(error => console.log(error));
  }, []);
  const errorsdiv = (
    <div
      className="projectsitemap__box__error"
      dangerouslySetInnerHTML={{ __html: errors }}
    ></div>
  );
  const errorspan = errorsdiv?.props?.dangerouslySetInnerHTML?.__html;
  const errornumber = errorspan?.split("")[6];
  const truncate = input =>
    input?.length > 50 ? `${input.substring(0, 50)}...` : input;
  return (
    <>
      {open ? (
        <>
          <div
            key={e.url}
            className="projectsitemap__box boxshadow"
            onClick={() => setOpen(false)}
          >
            <div className="projectsitemap__box__link">
              <p>{truncate(e.url)}</p>
            </div>
            <div className="projectsitemap__box__scoremob">
              <CgSmartphone
                style={{
                  color: get.color(e?.lastMobileResult?.scores[6].value * 100),
                }}
                className="card__links"
              />
              <p
                style={{
                  color: get.color(e?.lastMobileResult?.scores[6].value * 100),
                }}
              >
                {Math.round(e?.lastMobileResult?.scores[6].value * 100)}%
              </p>
            </div>
            <div className="projectsitemap__box__scoredesk">
              <CgScreen
                style={{
                  color: get.color(e?.lastDesktopResult?.scores[6].value * 100),
                }}
                className="card__links"
              />
              <p
                style={{
                  color: get.color(e?.lastDesktopResult?.scores[6].value * 100),
                }}
              >
                {Math.round(e?.lastDesktopResult?.scores[6].value * 100)}%
              </p>
            </div>
            {errornumber === 0 ? (
              <div className="projectsitemap__box__error">
                <FiAlertTriangle />
              </div>
            ) : (
              <div className="projectsitemap__box__check">
                <FiCheckCircle />
              </div>
            )}
            <FiChevronUp className="projectsitemap__box__icon" />
            <div className="projectsitemap__box__button">
              <Button
                className="btn btn--primary projectsitemap__box__button_style"
                icon="edit"
              />
            </div>
          </div>
          <div className="projectsitemap__data boxshadow">
            <div className="projectlink">
              <div className="projectlink__scores__mobile projectlink__scores__mobile--active">
                <CgSmartphone
                  style={{
                    color: get.color(
                      e?.lastMobileResult?.scores[6].value * 100
                    ),
                  }}
                  className="card__mobile"
                />
                <p
                  style={{
                    color: get.color(
                      e?.lastMobileResult?.scores[6].value * 100
                    ),
                  }}
                >
                  {Math.round(e?.lastMobileResult?.scores[6].value * 100)}%
                </p>
              </div>
              <div className="projectlink__scores__desktop">
                <CgScreen
                  style={{
                    color: get.color(
                      e?.lastDesktopResult?.scores[6].value * 100
                    ),
                  }}
                  className="card__mobile"
                />
                <p
                  style={{
                    color: get.color(
                      e?.lastDesktopResult?.scores[6].value * 100
                    ),
                  }}
                >
                  {Math.round(e?.lastDesktopResult?.scores[6].value * 100)}%
                </p>
              </div>
              <div className="projectlink__error__svg">
                <FiAlertTriangle />
                <div
                  className="projectsitemap__box__error__mob"
                  dangerouslySetInnerHTML={{ __html: errors }}
                ></div>
              </div>
            </div>
            <div className="projectsitemap__data__buttons">
              <div>
                <Button
                  className={"projectlink__button btn btn--primary"}
                  icon="eye"
                />
              </div>
              <div>
                <Button
                  className={"projectlink__button btn btn--primary"}
                  icon="edit"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          key={e.url}
          className="projectsitemap__box boxshadow"
          onClick={() => setOpen(true)}
        >
          <div className="projectsitemap__box__link">
            <p>{truncate(e.url)}</p>
          </div>
          <div className="projectsitemap__box__scoremob">
            <CgSmartphone
              style={{
                color: get.color(e?.lastMobileResult?.scores[6].value * 100),
              }}
              className="card__links"
            />
            <p
              style={{
                color: get.color(e?.lastMobileResult?.scores[6].value * 100),
              }}
            >
              {Math.round(e?.lastMobileResult?.scores[6].value * 100)}%
            </p>
          </div>
          <div className="projectsitemap__box__scoredesk">
            <CgScreen
              style={{
                color: get.color(e?.lastDesktopResult?.scores[6].value * 100),
              }}
              className="card__links"
            />
            <p
              style={{
                color: get.color(e?.lastDesktopResult?.scores[6].value * 100),
              }}
            >
              {Math.round(e?.lastDesktopResult?.scores[6].value * 100)}%
            </p>
          </div>
          {errornumber === 0 ? (
            <div className="projectsitemap__box__error">
              {" "}
              <FiAlertTriangle />
            </div>
          ) : (
            <div className="projectsitemap__box__check">
              {" "}
              <FiCheckCircle />
            </div>
          )}
          <FiChevronDown className="projectsitemap__box__icon" />
          <div className="projectsitemap__box__button">
            <Button
              className="btn btn--primary projectsitemap__box__button_style"
              icon="edit"
            />
          </div>
        </div>
      )}
    </>
  );
};
