import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiAlertTriangle, FiInfo, FiChevronDown } from "react-icons/fi";
import { CgOptions, CgScreen, CgSmartphone } from "react-icons/cg";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";
import { Grid } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner";
import axios from "axios";
import { get } from "../helpers/get";

import { Linechart } from "../components/atoms/Linechart";
import { Button } from "../components/atoms/Button";
import { Metrics } from "../components/atoms/Metrics";
import { Performance } from "../components/atoms/Performance";
import { DetailBackground, DetailBackground2 } from "../components/atoms/Svg";
import { Sitemap } from "../components/atoms/Sitemap";
import { Barchart } from "../components/atoms/Barchart";

import { Layout } from "../components/molecules/Layout";
import { projectdetail, projectSiteUrls } from "../api";
import { paginate } from "../helpers/paginate";

export const Project = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [projectid, setProjectid] = useState(location.pathname.split("/")[2]);
  const currentUrl = window.location.href.split("/");
  const currentPath = currentUrl[currentUrl.length - 1];
  const [project, setProject] = useState([]);
  const [projectsiteurls, setProjectSiteUrls] = useState([]);
  const [barchart, setBarchart] = useState("TCP");
  let [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    axios
      .get(projectdetail(currentPath))
      .then(response => {
        setProject(response.data);
      })
      .catch(error => console.log(error));
  }, [projectid]);
  useEffect(() => {
    axios
      .get(projectSiteUrls({ projectid, page }))
      .then(response => {
        setProjectSiteUrls(response.data["hydra:member"]);
        setPagination(response.data["hydra:view"]);
      })
      .catch(error => console.log(error));
  }, [page]);
  const lastpage = pagination["hydra:last"]?.slice(-2);
  return (
    <>
      <Layout headerImg={project.logo}>
        {project.logo ? (
          <>
            <section className="projecttop">
              <div className="projecttop__scores">
                <div className="projecttop__scores__mobile projecttop__scores__mobile--active">
                  <CgSmartphone
                    style={{
                      color: get.color(
                        project?.mainurl_id?.lastMobileResult?.scores[6]
                          ?.valueProcent
                      ),
                    }}
                    className="card__mobile"
                  />
                  <p
                    style={{
                      color: get.color(
                        project?.mainurl_id?.lastMobileResult?.scores[6]
                          ?.valueProcent
                      ),
                    }}
                  >
                    {Math.round(
                      project?.mainurl_id?.lastMobileResult?.scores[6]
                        ?.valueProcent
                    )}
                    %
                  </p>
                </div>
                <div className="projecttop__scores__desktop">
                  <CgScreen
                    style={{
                      color: get.color(
                        project?.mainurl_id?.lastDesktopResult?.scores[6]
                          ?.valueProcent
                      ),
                    }}
                    className="card__mobile"
                  />
                  <p
                    style={{
                      color: get.color(
                        project?.mainurl_id?.lastDesktopResult?.scores[6]
                          ?.valueProcent
                      ),
                    }}
                  >
                    {Math.round(
                      project?.mainurl_id?.lastDesktopResult?.scores[6]
                        ?.valueProcent
                    )}
                    %
                  </p>
                </div>
              </div>
              {project.securityUpdate && (
                <div className="projecttop__error">
                  <FiAlertTriangle className="projecttop__error__svg" />
                  <p className="projecttop__error__text">
                    There are security updates!
                  </p>
                </div>
              )}
              <div className="projecttop__buttons">
                <Button
                  className="btn btn--primary projecttop__buttons__btn projecttop__buttons__edit"
                  icon="edit"
                  // onClick={() => navigate("editproject")}
                  path={`projects/${projectid}/editproject`}
                />
                <Button
                  className="btn btn--secondary projecttop__buttons__btn"
                  icon="trash"
                />
              </div>
            </section>
            <section className="projectinfo">
              <div className="projectinfo__performance">
                <h2>
                  {barchart} -{" "}
                  {(barchart == "TCP" && "Performance") ||
                    (barchart == "TCA" && "Accessibility") ||
                    (barchart == "TCS" && "SEO") ||
                    (barchart == "TCBP" && "Best Practices")}
                </h2>
                <div className="projectinfo__performance__container boxshadow">
                  <Barchart type={barchart} />
                </div>
                <div className="projectperformancegrid">
                  <Performance
                    style={barchart == "TCP" ? true : false}
                    onClick={name => setBarchart(name)}
                    tip="Performance of the site"
                    name="TCP"
                    value={
                      project?.mainurl_id?.lastMobileResult?.scores[6]
                        ?.valueProcent
                    }
                  />
                  <Performance
                    style={barchart == "TCA" ? true : false}
                    onClick={name => setBarchart(name)}
                    tip="Accesebility of the site"
                    name="TCA"
                    value={
                      project?.mainurl_id?.lastMobileResult?.scores[8]
                        ?.valueProcent
                    }
                  />
                  <Performance
                    style={barchart == "TCS" ? true : false}
                    onClick={name => setBarchart(name)}
                    name="TCS"
                    value={
                      project?.mainurl_id?.lastMobileResult?.scores[7]
                        ?.valueProcent
                    }
                  />
                  <Performance
                    style={barchart == "TCBP" ? true : false}
                    onClick={name => setBarchart(name)}
                    name="TCBP"
                    value={
                      project?.mainurl_id?.lastMobileResult?.scores[10]
                        ?.valueProcent
                    }
                  />
                </div>
              </div>
              <div className="projectinfo__pages">
                <div className="legend">
                  <h2>Pages</h2>
                  <ul>
                    <li className="listgreen">Good</li>
                    <li className="listorange">Medium</li>
                    <li className="listred">Bad</li>
                  </ul>
                </div>
                <div className="projectinfo__pages__container boxshadow">
                  <Linechart projectid={projectid} />
                </div>
              </div>
            </section>
            <section className="projectmetrics">
              <h2>Metrics</h2>
              <section className="projectmetrics__boxcontainer">
                {project?.mainurl_id ? (
                  project?.mainurl_id?.lastMobileResult?.scores.map(e => {
                    if (
                      e.scoreType.type != "TCP" &&
                      e.scoreType.type != "TCA" &&
                      e.scoreType.type != "TCS" &&
                      e.scoreType.type != "TCBP" &&
                      e.scoreType.type != "TCPWA"
                    ) {
                      return (
                        <Metrics
                          colorCard={get.color(e.valueProcent)}
                          name={e.scoreType.description}
                          url={project?.mainUrl}
                          content={e.valueProcent + "%"}
                        />
                      );
                    }
                  })
                ) : (
                  <Oval
                    height="20"
                    width="20"
                    color="#0A006F"
                    ariaLabel="loading"
                  />
                )}
              </section>
            </section>
            <section className="projectsitemap">
              <h2>Sitemap</h2>
              <div className="projectsitemap__headings">
                <div className="projectsitemap__headings__urls">Urls</div>
                <div className="projectsitemap__headings__mob">
                  Mobile score
                </div>
                <div className="projectsitemap__headings__desk">
                  Desktop score
                </div>
                <div className="projectsitemap__headings__error">H1 errors</div>
                <div className="projectsitemap__headings__empty"></div>
              </div>
              <section className="projectsitemap__boxcontainer">
                {projectsiteurls?.map(e => (
                  <Link to={"/siteurlDetail/" + e["@id"].split("/")[3]}>
                    <Sitemap e={e} />
                  </Link>
                ))}
              </section>
              <section className="pagination">
                <div className="pagination__center">
                  <button
                    className="pagination__pagebutton"
                    onClick={() => setPage(paginate(page, "Back"))}
                    disabled={page == 1}
                  >
                    <FaArrowCircleLeft
                      style={{ color: page == 1 && "grey" }}
                      className="pagination__pageicon"
                    />
                  </button>
                  <span className="pagination__count">{page}</span>
                  <button
                    className="pagination__pagebutton"
                    onClick={() => setPage(paginate(page, "Next"))}
                    disabled={page == lastpage}
                  >
                    <FaArrowCircleRight
                      style={{ color: page == lastpage && "grey" }}
                      className="pagination__pageicon"
                    />
                  </button>
                </div>
              </section>
            </section>
          </>
        ) : (
          <div class="center-screen">
            <Grid
              height="100"
              width="100"
              color="#0A006F"
              ariaLabel="loading"
            />
          </div>
        )}
      </Layout>
      <DetailBackground className="detail_background" />
      <DetailBackground2 className="detail_background2" />
    </>
  );
};
