import { MenuItem } from "../atoms/MenuItem";
import { Layout } from "./Layout";
import { MenuBackground, MenuBackgroundMobile } from "../atoms/Svg";
import { menu } from "../../helpers/config";

export const Menu = () => {
  return (
    <>
      <Layout headerName={"Menu"}>
        <section className="row">
          <h3 className="row__title">Manage</h3>
          <div className="row__cards">
            {menu?.manage?.map(e => {
              return (
                <MenuItem
                  key={e.name}
                  name={e.name}
                  path={e.path}
                  img={e.img}
                />
              );
            })}
          </div>
        </section>
        <section className="row">
          <h3 className="row__title">Synthetic</h3>
          <div className="row__cards">
            {menu?.synthetic?.map(e => {
              return (
                <MenuItem
                  key={e.name}
                  name={e.name}
                  path={e.path}
                  img={e.img}
                />
              );
            })}
          </div>
        </section>
        <section className="row">
          <h3 className="row__title">Info</h3>
          <div className="row__cards">
            {menu?.info?.map(e => {
              return (
                <MenuItem
                  key={e.name}
                  name={e.name}
                  path={e.path}
                  img={e.img}
                />
              );
            })}
          </div>
        </section>
      </Layout>
      <MenuBackground className={"menu_background"} />
      <MenuBackgroundMobile className="menu_mobile_background" />
    </>
  );
};
