//// Load users, ProjectTyoes from api ////

export const config = {
  projects() {
    return ["Drupal", "Wordpress"];
  },
  users() {
    return ["Admin"];
  },
};

export const menu = {
  manage: [
    { name: "Dashboard", path: "/dashboard", img: "menu_dashboard.png" },
    { name: "Dashboard 2", path: "/dashboard2", img: "menu_dashboard.png" },
    { name: "Projects", path: "/projects", img: "menu_projects.png" },
    {
      name: "Projects Types",
      path: "/projecttypes",
      img: "menu_projecttypes.png",
    },
    {
      name: "Score Types",
      path: "/scoretypes",
      img: "menu_scoretypes.png",
    },
    { name: "Sitemaps", path: "/sitemaps", img: "menu_sitemaps.png" },
    { name: "Users", path: "/users", img: "menu_users.png" },
  ],

  synthetic: [
    { name: "Jobs", path: "/jobs", img: "menu_jobs.png" },
    { name: "Categories", path: "/categories", img: "menu_categories.png" },
    { name: "Test", path: "/test", img: "menu_test.png" },
  ],

  info: [
    {
      name: "Documentation",
      path: "/documentation",
      img: "menu_documentation.png",
    },
    { name: "Log out", img: "menu_logout.png" },
  ],
};
